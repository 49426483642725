import React from 'react';
import { useIntl } from 'react-intl';
import styled, { keyframes } from 'styled-components';
import { FaCode, FaLightbulb, FaHandshake, FaRocket } from 'react-icons/fa';

const fadeInUp = keyframes`
  from { opacity: 0; transform: translateY(30px); }
  to { opacity: 1; transform: translateY(0); }
`;

const PageWrapper = styled.div`
  background: linear-gradient(180deg, #2a5298, #1e3c72);
  color: #ffffff;
  padding: 80px 0;
  min-height: 100vh;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const PageTitle = styled.h1`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  animation: ${fadeInUp} 1s ease-out;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
`;

const Section = styled.section`
  margin-bottom: 4rem;
  animation: ${fadeInUp} 1s ease-out;
  animation-fill-mode: both;
  animation-delay: ${props => props.delay}s;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #7efff5;
  margin-bottom: 1.5rem;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
`;

const ValueGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
`;

const ValueCard = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const ValueIcon = styled.div`
  font-size: 3rem;
  color: #7efff5;
  margin-bottom: 1rem;
`;

const ValueTitle = styled.h3`
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 1rem;
`;

const ValueDescription = styled.p`
  font-size: 1rem;
  color: #f0f0f0;
`;

const TeamSection = styled(Section)`
  text-align: center;
`;

const TeamDescription = styled(Paragraph)`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const AboutPage = () => {
  const intl = useIntl();
  const companyValues = [
    { icon: <FaCode />, titleId: 'about.values.codeExcellence.title', descriptionId: 'about.values.codeExcellence.description' },
    { icon: <FaLightbulb />, titleId: 'about.values.innovation.title', descriptionId: 'about.values.innovation.description' },
    { icon: <FaHandshake />, titleId: 'about.values.clientPartnership.title', descriptionId: 'about.values.clientPartnership.description' },
    { icon: <FaRocket />, titleId: 'about.values.continuousGrowth.title', descriptionId: 'about.values.continuousGrowth.description' }
  ];

  return (
    <PageWrapper>
      <Container>
        <PageTitle>{intl.formatMessage({ id: 'about.pageTitle' })}</PageTitle>

        <Section delay={0.2}>
          <SectionTitle>{intl.formatMessage({ id: 'about.story.title' })}</SectionTitle>
          <Paragraph>{intl.formatMessage({ id: 'about.story.paragraph1' })}</Paragraph>
          <Paragraph>{intl.formatMessage({ id: 'about.story.paragraph2' })}</Paragraph>
        </Section>

        <Section delay={0.4}>
          <SectionTitle>{intl.formatMessage({ id: 'about.mission.title' })}</SectionTitle>
          <Paragraph>{intl.formatMessage({ id: 'about.mission.paragraph1' })}</Paragraph>
          <Paragraph>{intl.formatMessage({ id: 'about.mission.paragraph2' })}</Paragraph>
        </Section>

        <Section delay={0.6}>
          <SectionTitle>{intl.formatMessage({ id: 'about.values.title' })}</SectionTitle>
          <Paragraph>{intl.formatMessage({ id: 'about.values.description' })}</Paragraph>
          <ValueGrid>
            {companyValues.map((value, index) => (
              <ValueCard key={index}>
                <ValueIcon>{value.icon}</ValueIcon>
                <ValueTitle>{intl.formatMessage({ id: value.titleId })}</ValueTitle>
                <ValueDescription>{intl.formatMessage({ id: value.descriptionId })}</ValueDescription>
              </ValueCard>
            ))}
          </ValueGrid>
        </Section>

        <TeamSection delay={0.8}>
          <SectionTitle>{intl.formatMessage({ id: 'about.team.title' })}</SectionTitle>
          <TeamDescription>{intl.formatMessage({ id: 'about.team.description1' })}</TeamDescription>
          <TeamDescription>{intl.formatMessage({ id: 'about.team.description2' })}</TeamDescription>
        </TeamSection>

        <Section delay={1}>
          <SectionTitle>{intl.formatMessage({ id: 'about.expertise.title' })}</SectionTitle>
          <Paragraph>{intl.formatMessage({ id: 'about.expertise.introduction' })}</Paragraph>
          <ul>
            {intl.formatMessage({ id: 'about.expertise.list' }).split(',').map((item, index) => (
              <li key={index}>{item.trim()}</li>
            ))}
          </ul>
          <Paragraph>{intl.formatMessage({ id: 'about.expertise.conclusion' })}</Paragraph>
        </Section>

        <Section delay={1.2}>
          <SectionTitle>{intl.formatMessage({ id: 'about.commitment.title' })}</SectionTitle>
          <Paragraph>{intl.formatMessage({ id: 'about.commitment.paragraph1' })}</Paragraph>
          <Paragraph>{intl.formatMessage({ id: 'about.commitment.paragraph2' })}</Paragraph>
        </Section>
      </Container>
    </PageWrapper>
  );
};

export default AboutPage;