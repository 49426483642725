import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled, { keyframes } from 'styled-components';
import { FaCode, FaReact, FaAngular, FaDatabase, FaServer, FaCalculator, FaArrowRight, FaWordpress, FaMobile, FaDesktop, FaGlobe } from 'react-icons/fa';

const fadeInUp = keyframes`
  from { opacity: 0; transform: translateY(30px); }
  to { opacity: 1; transform: translateY(0); }
`;

const PageWrapper = styled.div`
  background: linear-gradient(180deg, #2a5298, #1e3c72);
  color: #ffffff;
  padding: 80px 0;
  min-height: 100vh;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const PageTitle = styled.h1`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  animation: ${fadeInUp} 1s ease-out;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
`;

const ServiceCard = styled.div`
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  padding: 2rem;
  transition: all 0.4s ease;
  animation: ${fadeInUp} 1s ease-out;
  animation-fill-mode: both;
  animation-delay: ${props => props.delay}s;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &:hover {
    transform: translateY(-10px) scale(1.05);
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
`;

const ServiceIcon = styled.div`
  font-size: 3rem;
  color: #7efff5;
  margin-bottom: 1rem;
`;

const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #ffffff;
`;

const ServiceDescription = styled.p`
  font-size: 1rem;
  opacity: 0.95;
  color: #f0f0f0;
  line-height: 1.6;
`;

const CalculatorSection = styled.div`
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  padding: 2rem;
  margin-top: 4rem;
  animation: ${fadeInUp} 1s ease-out 0.6s;
  animation-fill-mode: both;
`;

const CalculatorTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #7efff5;
  text-align: center;
`;

const CalculatorForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #7efff5;
`;

const Select = styled.select`
  padding: 0.75rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #1e3c72;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover, &:focus {
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(126, 255, 245, 0.5);
  }
`;

const CalculateButton = styled.button`
  background-color: #7efff5;
  color: #1e3c72;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: 700;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  grid-column: 1 / -1;

  &:hover {
    background-color: #ffffff;
    color: #4a00e0;
    transform: translateY(-5px) scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }

  svg {
    margin-left: 10px;
  }
`;

const EstimatedCost = styled.div`
  text-align: center;
  margin-top: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #7efff5;
`;

const ContactButton = styled(CalculateButton)`
  background-color: #4a00e0;
  color: #ffffff;
  margin-top: 2rem;

  &:hover {
    background-color: #7efff5;
    color: #1e3c72;
  }
`;

const ServicesPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [projectType, setProjectType] = useState('');
  const [projectSize, setProjectSize] = useState('');
  const [complexity, setComplexity] = useState('');
  const [designRequirement, setDesignRequirement] = useState('');
  const [estimatedCost, setEstimatedCost] = useState(null);

  const services = [
    { icon: <FaCode />, titleId: 'services.net.title', descriptionId: 'services.net.description' },
    { icon: <FaReact />, titleId: 'services.react.title', descriptionId: 'services.react.description' },
    { icon: <FaAngular />, titleId: 'services.angular.title', descriptionId: 'services.angular.description' },
    { icon: <FaDatabase />, titleId: 'services.database.title', descriptionId: 'services.database.description' },
    { icon: <FaServer />, titleId: 'services.backend.title', descriptionId: 'services.backend.description' },
    { icon: <FaWordpress />, titleId: 'services.wordpress.title', descriptionId: 'services.wordpress.description' },
    { icon: <FaCalculator />, titleId: 'services.custom.title', descriptionId: 'services.custom.description' },
  ];

  const calculateCost = () => {
    const baseRates = {
      web: 2000,
      mobile: 3000,
      desktop: 2500,
      wordpress: 1500
    };

    const sizeMultipliers = {
      small: 1,
      medium: 1.5,
      large: 2
    };

    const complexityMultipliers = {
      low: 1,
      medium: 1.3,
      high: 1.6
    };

    const designMultipliers = {
      basic: 1,
      custom: 1.4,
      premium: 1.8
    };

    const baseRate = baseRates[projectType] || 0;
    const sizeMultiplier = sizeMultipliers[projectSize] || 0;
    const complexityMultiplier = complexityMultipliers[complexity] || 0;
    const designMultiplier = designMultipliers[designRequirement] || 0;

    return baseRate * sizeMultiplier * complexityMultiplier * designMultiplier;
  };

  const handleCalculate = (e) => {
    e.preventDefault();
    const cost = calculateCost();
    setEstimatedCost(cost);
  };

  const handleContact = () => {
    navigate('/contact', { state: { projectType, projectSize, complexity, designRequirement, estimatedCost } });
  };

  return (
    <PageWrapper>
      <Container>
        <PageTitle>{intl.formatMessage({ id: 'services.pageTitle' })}</PageTitle>
        <ServiceGrid>
          {services.map((service, index) => (
            <ServiceCard key={index} delay={0.1 * (index + 1)}>
              <ServiceIcon>{service.icon}</ServiceIcon>
              <ServiceTitle>{intl.formatMessage({ id: service.titleId })}</ServiceTitle>
              <ServiceDescription>{intl.formatMessage({ id: service.descriptionId })}</ServiceDescription>
            </ServiceCard>
          ))}
        </ServiceGrid>
        <CalculatorSection>
          <CalculatorTitle>{intl.formatMessage({ id: 'services.calculator.title' })}</CalculatorTitle>
          <CalculatorForm onSubmit={handleCalculate}>
            <FormGroup>
              <Label htmlFor="projectType">{intl.formatMessage({ id: 'services.calculator.projectType' })}</Label>
              <Select id="projectType" value={projectType} onChange={(e) => setProjectType(e.target.value)}>
                <option value="">{intl.formatMessage({ id: 'services.calculator.selectType' })}</option>
                <option value="web">{intl.formatMessage({ id: 'services.calculator.webApp' })}</option>
                <option value="mobile">{intl.formatMessage({ id: 'services.calculator.mobileApp' })}</option>
                <option value="desktop">{intl.formatMessage({ id: 'services.calculator.desktopApp' })}</option>
                <option value="wordpress">{intl.formatMessage({ id: 'services.calculator.wordpressWebsite' })}</option>
              </Select>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="projectSize">{intl.formatMessage({ id: 'services.calculator.projectSize' })}</Label>
              <Select id="projectSize" value={projectSize} onChange={(e) => setProjectSize(e.target.value)}>
                <option value="">{intl.formatMessage({ id: 'services.calculator.selectSize' })}</option>
                <option value="small">{intl.formatMessage({ id: 'services.calculator.small' })}</option>
                <option value="medium">{intl.formatMessage({ id: 'services.calculator.medium' })}</option>
                <option value="large">{intl.formatMessage({ id: 'services.calculator.large' })}</option>
              </Select>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="complexity">{intl.formatMessage({ id: 'services.calculator.complexity' })}</Label>
              <Select id="complexity" value={complexity} onChange={(e) => setComplexity(e.target.value)}>
                <option value="">{intl.formatMessage({ id: 'services.calculator.selectComplexity' })}</option>
                <option value="low">{intl.formatMessage({ id: 'services.calculator.low' })}</option>
                <option value="medium">{intl.formatMessage({ id: 'services.calculator.medium' })}</option>
                <option value="high">{intl.formatMessage({ id: 'services.calculator.high' })}</option>
              </Select>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="designRequirement">{intl.formatMessage({ id: 'services.calculator.designRequirement' })}</Label>
              <Select id="designRequirement" value={designRequirement} onChange={(e) => setDesignRequirement(e.target.value)}>
                <option value="">{intl.formatMessage({ id: 'services.calculator.selectDesign' })}</option>
                <option value="basic">{intl.formatMessage({ id: 'services.calculator.basic' })}</option>
                <option value="custom">{intl.formatMessage({ id: 'services.calculator.custom' })}</option>
                <option value="premium">{intl.formatMessage({ id: 'services.calculator.premium' })}</option>
              </Select>
            </FormGroup>
            <CalculateButton type="submit">
              {intl.formatMessage({ id: 'services.calculator.calculateButton' })} <FaArrowRight />
            </CalculateButton>
          </CalculatorForm>
          {estimatedCost !== null && (
            <>
              <EstimatedCost>
                {intl.formatMessage({ id: 'services.calculator.estimatedCost' }, { cost: estimatedCost.toLocaleString() })}
              </EstimatedCost>
              <ContactButton onClick={handleContact}>
                {intl.formatMessage({ id: 'services.calculator.contactButton' })} <FaArrowRight />
              </ContactButton>
            </>
          )}
        </CalculatorSection>
      </Container>
    </PageWrapper>
  );
};

export default ServicesPage;