import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled, { keyframes } from 'styled-components';
import { FaArrowRight, FaSpinner, FaCode, FaMobile, FaCloudUploadAlt } from 'react-icons/fa';

const fadeInUp = keyframes`
  from { opacity: 0; transform: translateY(30px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Section = styled.section`
  background: linear-gradient(180deg, #1e3c72, #2a5298);
  color: #ffffff;
  padding: 100px 0;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  margin-bottom: 3rem;
  animation: ${fadeInUp} 1s ease-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: #ffffff;
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const ProjectCard = styled.article`
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.4s ease;
  animation: ${fadeInUp} 1s ease-out;
  animation-fill-mode: both;
  animation-delay: ${props => props.delay}s;

  &:hover {
    transform: translateY(-10px) scale(1.05);
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const ProjectContent = styled.div`
  padding: 1.5rem;
`;

const ProjectTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #7efff5;
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  opacity: 0.95;
  margin-bottom: 1rem;
  line-height: 1.6;
  color: #ffffff;
`;

const ProjectDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: #f0f0f0;
`;

const ViewAllButton = styled.button`
  background-color: #7efff5;
  color: #1e3c72;
  padding: 15px 40px;
  font-size: 1.2rem;
  font-weight: 700;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.4s ease;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover, &:focus {
    background-color: #ffffff;
    color: #4a00e0;
    transform: translateY(-5px) scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
    outline: none;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(126, 255, 245, 0.5), 0 15px 30px rgba(0, 0, 0, 0.3);
  }

  svg {
    margin-left: 10px;
  }
`;

const LoadingSpinner = styled(FaSpinner)`
  animation: spin 1s linear infinite;
  font-size: 2rem;
  margin: 2rem 0;
  color: #7efff5;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ErrorMessage = styled.p`
  color: #ff6b6b;
  font-size: 1.2rem;
  margin: 2rem 0;
`;

const createRandomProject = (intl) => {
  const icons = [<FaCode />, <FaMobile />, <FaCloudUploadAlt />];
  const randomIcon = icons[Math.floor(Math.random() * icons.length)];
  return {
    id: 'random-project',
    name: intl.formatMessage({ id: 'projects.random.name' }),
    description: intl.formatMessage({ id: 'projects.random.description' }),
    imageUrl: '/api/placeholder/400/300',
    status: intl.formatMessage({ id: 'projects.status.inProgress' }),
    clientName: intl.formatMessage({ id: 'projects.random.client' }),
    icon: randomIcon
  };
};

const RecentProjects = () => {
  const intl = useIntl();
  const [recentProjects, setRecentProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchRecentProjects();
  }, []);

  const fetchRecentProjects = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await fetch('/api/projects/recent?count=3');
      if (!response.ok) {
        throw new Error(intl.formatMessage({ id: 'projects.fetchError' }));
      }
      const data = await response.json();
      setRecentProjects(data.length > 0 ? data : [createRandomProject(intl)]);
    } catch (error) {
      console.error(intl.formatMessage({ id: 'projects.fetchError' }), error);
      setRecentProjects([createRandomProject(intl)]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Section>
      <SectionTitle>{intl.formatMessage({ id: 'projects.sectionTitle' })}</SectionTitle>
      {isLoading ? (
        <LoadingSpinner aria-label={intl.formatMessage({ id: 'projects.loading' })} />
      ) : (
        <>
          <ProjectGrid>
            {recentProjects.map((project, index) => (
              <ProjectCard key={project.id} delay={0.2 * (index + 1)}>
                <ProjectImage src={project.imageUrl} alt={`${project.name} ${intl.formatMessage({ id: 'projects.imageAlt' })}`} />
                <ProjectContent>
                  <ProjectTitle>{project.name}</ProjectTitle>
                  <ProjectDescription>{project.description}</ProjectDescription>
                  <ProjectDetails>
                    <span>{intl.formatMessage({ id: 'projects.status' })}: {project.status}</span>
                    <span>{intl.formatMessage({ id: 'projects.client' })}: {project.clientName}</span>
                  </ProjectDetails>
                </ProjectContent>
              </ProjectCard>
            ))}
          </ProjectGrid>
          <ViewAllButton>
            {intl.formatMessage({ id: 'projects.viewAll' })} <FaArrowRight aria-hidden="true" />
          </ViewAllButton>
        </>
      )}
    </Section>
  );
};

export default RecentProjects;