import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { GB, PL, DE, ES } from 'country-flag-icons/react/3x2'
import { LOCALES } from '../i18n/locales';

const LanguageSelectorWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 20px;
`;

const SelectedLanguage = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
 
  &:hover {
    color: #7efff5;
    transform: translateY(-2px);
  }
`;

const LanguageOptions = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #2a5298;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: ${props => props.isOpen ? 'block' : 'none'};

  @media (max-width: 768px) {
    position: static;
    margin-top: 10px;
  }
`;

const LanguageOption = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
 
  &:hover {
    background-color: #3a62a8;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const FlagIcon = styled.span`
  display: inline-flex;
  align-items: center;
  width: 24px;
  height: 18px;
  margin-right: 8px;
 
  svg {
    width: 100%;
    height: 100%;
  }
`;

const LanguageName = styled.span`
  font-size: 14px;
`;

const LanguageSelector = ({ setCurrentLocale, currentLocale }) => {
  const [isOpen, setIsOpen] = useState(false);

  const languageOptions = [
    { value: LOCALES.ENGLISH, label: 'English', flag: <GB /> },
    { value: LOCALES.POLISH, label: 'Polski', flag: <PL /> },
    { value: LOCALES.GERMAN, label: 'Deutsch', flag: <DE /> },
    { value: LOCALES.SPANISH, label: 'Español', flag: <ES /> },
  ];

  const getSelectedLanguage = useCallback(() => {
    return languageOptions.find(option => option.value === currentLocale) || languageOptions[0];
  }, [currentLocale]);

  const handleLanguageChange = (locale) => {
    setCurrentLocale(locale);
    setIsOpen(false);
  };

  useEffect(() => {
    console.log('Current locale:', currentLocale);
    console.log('Selected language:', getSelectedLanguage());
  }, [currentLocale, getSelectedLanguage]);

  return (
    <LanguageSelectorWrapper>
      <SelectedLanguage onClick={() => setIsOpen(!isOpen)}>
        <FlagIcon>{getSelectedLanguage().flag}</FlagIcon>
        <LanguageName>{getSelectedLanguage().label}</LanguageName>
      </SelectedLanguage>
      <LanguageOptions isOpen={isOpen}>
        {languageOptions.map(option => (
          <LanguageOption key={option.value} onClick={() => handleLanguageChange(option.value)}>
            <FlagIcon>{option.flag}</FlagIcon>
            <LanguageName>{option.label}</LanguageName>
          </LanguageOption>
        ))}
      </LanguageOptions>
    </LanguageSelectorWrapper>
  );
};

export default LanguageSelector;