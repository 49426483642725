import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled, { keyframes } from 'styled-components';
import { FaSpinner, FaCode, FaMobile, FaCloudUploadAlt, FaSearch, FaFilter, FaRobot, FaDatabase, FaLock } from 'react-icons/fa';

const fadeInUp = keyframes`
  from { opacity: 0; transform: translateY(30px); }
  to { opacity: 1; transform: translateY(0); }
`;

const PageWrapper = styled.div`
  background: linear-gradient(180deg, #2a5298, #1e3c72);
  color: #ffffff;
  padding: 80px 0 80px;
  text-align: center;
  position: relative;
  overflow: hidden;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  flex-grow: 1;
`;

const PageTitle = styled.h1`
  font-size: 3rem;
  color: #ffffff;
  text-align: center;
  margin-bottom: 2rem;
  animation: ${fadeInUp} 1s ease-out;
`;

const SearchFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  animation: ${fadeInUp} 1s ease-out 0.2s;
  animation-fill-mode: both;
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 25px;
  margin-right: 1rem;
`;

const FilterButton = styled.button`
  background-color: #7efff5;
  color: #1e3c72;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #ffffff;
    color: #4a00e0;
  }

  svg {
    margin-right: 10px;
  }
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
`;

const ProjectCard = styled.article`
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.4s ease;
  animation: ${fadeInUp} 1s ease-out;
  animation-fill-mode: both;
  animation-delay: ${props => props.delay}s;

  &:hover {
    transform: translateY(-10px) scale(1.05);
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const ProjectContent = styled.div`
  padding: 1.5rem;
`;

const ProjectTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #7efff5;
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  opacity: 0.95;
  margin-bottom: 1rem;
  line-height: 1.6;
  color: #ffffff;
`;

const ProjectDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: #f0f0f0;
`;

const LoadingSpinner = styled(FaSpinner)`
  animation: spin 1s linear infinite;
  font-size: 3rem;
  margin: 2rem auto;
  display: block;
  color: #7efff5;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ErrorMessage = styled.p`
  color: #ff6b6b;
  font-size: 1.2rem;
  text-align: center;
  margin: 2rem 0;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;
`;

const FilterPill = styled.button`
  background-color: ${props => props.active ? '#7efff5' : 'rgba(255, 255, 255, 0.15)'};
  color: ${props => props.active ? '#1e3c72' : '#ffffff'};
  border: none;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #7efff5;
    color: #1e3c72;
  }
`;

const createRandomProjects = (count, intl) => {
  const icons = [<FaCode />, <FaMobile />, <FaCloudUploadAlt />, <FaRobot />, <FaDatabase />, <FaLock />];
  const statuses = ['projects.status.inProgress', 'projects.status.completed', 'projects.status.planning'];
  const clients = ['projects.client.techInnovators', 'projects.client.globalSolutions', 'projects.client.futureSystems', 'projects.client.digitalDynamics', 'projects.client.ecoTech', 'projects.client.smartInnovations'];
  const projectTypes = ['projects.type.webDev', 'projects.type.mobileApp', 'projects.type.cloudSolution', 'projects.type.aiIntegration', 'projects.type.iotPlatform', 'projects.type.blockchain'];
  
  return Array.from({ length: count }, (_, index) => ({
    id: `sample-project-${index + 1}`,
    name: intl.formatMessage({ id: projectTypes[index % projectTypes.length] }),
    description: intl.formatMessage({ id: 'projects.description' }, { type: intl.formatMessage({ id: projectTypes[index % projectTypes.length] }).toLowerCase() }),
    imageUrl: `/api/placeholder/${400 + index}/${300 + index}`,
    status: intl.formatMessage({ id: statuses[Math.floor(Math.random() * statuses.length)] }),
    clientName: intl.formatMessage({ id: clients[index % clients.length] }),
    icon: icons[index % icons.length],
    type: intl.formatMessage({ id: projectTypes[index % projectTypes.length] })
  }));
};

const ProjectsPage = () => {
  const intl = useIntl();
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilter, setActiveFilter] = useState(intl.formatMessage({ id: 'projects.filter.all' }));

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await fetch('/api/projects');
      if (!response.ok) {
        throw new Error(intl.formatMessage({ id: 'projects.fetchError' }));
      }
      const data = await response.json();
      setProjects(data.length > 0 ? data : createRandomProjects(9, intl));
    } catch (error) {
      console.error(intl.formatMessage({ id: 'projects.fetchError' }), error);
      setProjects(createRandomProjects(9, intl));
      setError(intl.formatMessage({ id: 'projects.loadError' }));
    } finally {
      setIsLoading(false);
    }
  };

  const filteredProjects = projects.filter(project =>
    (project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    project.description.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (activeFilter === intl.formatMessage({ id: 'projects.filter.all' }) || project.type === activeFilter)
  );

  const uniqueProjectTypes = [intl.formatMessage({ id: 'projects.filter.all' }), ...new Set(projects.map(project => project.type))];

  return (
    <PageWrapper>
      <Container>
        <PageTitle>{intl.formatMessage({ id: 'projects.pageTitle' })}</PageTitle>
        
        <SearchFilterContainer>
          <SearchInput 
            type="text" 
            placeholder={intl.formatMessage({ id: 'projects.searchPlaceholder' })}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FilterButton>
            <FaFilter /> {intl.formatMessage({ id: 'projects.filterButton' })}
          </FilterButton>
        </SearchFilterContainer>

        <FilterContainer>
          {uniqueProjectTypes.map(type => (
            <FilterPill
              key={type}
              active={activeFilter === type}
              onClick={() => setActiveFilter(type)}
            >
              {type}
            </FilterPill>
          ))}
        </FilterContainer>

        {isLoading ? (
          <LoadingSpinner aria-label={intl.formatMessage({ id: 'projects.loading' })} />
        ) : (
          <>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <ProjectGrid>
              {filteredProjects.map((project, index) => (
                <ProjectCard key={project.id} delay={0.1 * (index + 1)}>
                  <ProjectImage src={project.imageUrl} alt={`${project.name} ${intl.formatMessage({ id: 'projects.imageAlt' })}`} />
                  <ProjectContent>
                    <ProjectTitle>{project.name}</ProjectTitle>
                    <ProjectDescription>{project.description}</ProjectDescription>
                    <ProjectDetails>
                      <span>{intl.formatMessage({ id: 'projects.statusLabel' })}: {project.status}</span>
                      <span>{intl.formatMessage({ id: 'projects.clientLabel' })}: {project.clientName}</span>
                    </ProjectDetails>
                  </ProjectContent>
                </ProjectCard>
              ))}
            </ProjectGrid>
          </>
        )}
      </Container>
    </PageWrapper>
  );
};

export default ProjectsPage;