import { LOCALES } from './locales';

export const messages = {
  [LOCALES.ENGLISH]: {
    'app.title': 'Kodikolab',
    'nav.home': 'Home',
    'nav.projects': 'Projects',
    'nav.services': 'Services',
    'nav.about': 'About',
    'nav.contact': 'Contact',
    'banner.title': 'Transform Your Vision with {company}',
    'banner.subtitle': 'Pioneering Software Solutions for the Digital Age',
    'banner.cta': 'Ignite Your Innovation',
    'feature.software': 'Software Development',
    'feature.mobile': 'Mobile Applications',
    'feature.cloud': 'Cloud Solutions',
    'feature.automation': 'Automation',
    'stats.clients': 'Satisfied Clients',
    'stats.projects': 'Completed Projects',
    'stats.satisfaction': 'Client Satisfaction',
    'why.sectionTitle': 'Why Choose Kodikolab?',
    'why.expertTeam.title': 'Expert Team',
    'why.expertTeam.description': 'Skilled professionals dedicated to your success',
    'why.innovativeSolutions.title': 'Innovative Solutions',
    'why.innovativeSolutions.description': 'Cutting-edge technology for modern challenges',
    'why.clientFocused.title': 'Client-Focused Approach',
    'why.clientFocused.description': 'Your vision, our mission',
    'why.leadingTechnology.title': 'Leading Technology',
    'why.leadingTechnology.description': 'Stay ahead with the latest tech advancements',
    'projects.sectionTitle': 'Recent Projects',
    'projects.random.name': 'Innovative Tech Solution',
    'projects.random.description': 'A cutting-edge project leveraging the latest technologies to solve complex business challenges.',
    'projects.status.inProgress': 'In Progress',
    'projects.random.client': 'Tech Innovators Inc.',
    'projects.fetchError': 'Failed to fetch recent projects',
    'projects.loading': 'Loading recent projects',
    'projects.imageAlt': 'project',
    'projects.status': 'Status',
    'projects.client': 'Client',
    'projects.viewAll': 'View All Projects',
    'news.sectionTitle': 'Latest News & Upcoming Events',
    'news.ai.title': 'Kodikolab launches new AI service',
    'news.ai.description': 'Revolutionizing business processes with cutting-edge AI solutions',
    'news.webinar.title': 'Upcoming webinar: Future of Web Development',
    'news.webinar.description': 'Join us on June 15th for insights into emerging web technologies',
    'news.hiring.title': 'We are hiring: Senior React Developer',
    'news.hiring.description': 'Be part of our innovative team and shape the future of web applications',
    'news.readMore': 'Read More',
    'footer.company': 'Kodikolab',
    'footer.slogan': 'Innovative solutions for your digital needs.',
    'footer.social.facebook': 'Facebook',
    'footer.social.twitter': 'Twitter',
    'footer.social.linkedin': 'LinkedIn',
    'footer.social.instagram': 'Instagram',
    'footer.quickLinks.title': 'Quick Links',
    'footer.quickLinks.home': 'Home',
    'footer.quickLinks.projects': 'Projects',
    'footer.quickLinks.about': 'About',
    'footer.quickLinks.contact': 'Contact',
    'footer.contact.title': 'Contact Us',
    'footer.contact.email': 'info@kodikolab.com',
    'footer.contact.phone': '+1 (123) 456-7890',
    'footer.contact.address': '123 Tech Street, Silicon Valley, CA 94000',
    'footer.copyright': '© {year} Kodikolab. All rights reserved.',
    'projects.pageTitle': 'Our Projects',
    'projects.searchPlaceholder': 'Search projects...',
    'projects.filterButton': 'Filter',
    'projects.loading': 'Loading projects',
    'projects.imageAlt': 'project',
    'projects.statusLabel': 'Status',
    'projects.clientLabel': 'Client',
    'projects.fetchError': 'Failed to fetch projects',
    'projects.loadError': 'Failed to load projects. Displaying sample data.',
    'projects.filter.all': 'All',
    'projects.status.inProgress': 'In Progress',
    'projects.status.completed': 'Completed',
    'projects.status.planning': 'Planning',
    'projects.client.techInnovators': 'Tech Innovators Inc.',
    'projects.client.globalSolutions': 'Global Solutions Ltd.',
    'projects.client.futureSystems': 'Future Systems Co.',
    'projects.client.digitalDynamics': 'Digital Dynamics',
    'projects.client.ecoTech': 'EcoTech Solutions',
    'projects.client.smartInnovations': 'Smart Innovations',
    'projects.type.webDev': 'Web Development',
    'projects.type.mobileApp': 'Mobile App',
    'projects.type.cloudSolution': 'Cloud Solution',
    'projects.type.aiIntegration': 'AI Integration',
    'projects.type.iotPlatform': 'IoT Platform',
    'projects.type.blockchain': 'Blockchain Implementation',
    'projects.description': 'A cutting-edge {type} project leveraging the latest technologies to solve complex business challenges. This project aims to revolutionize the industry with its unique approach and advanced features.',
    'services.pageTitle': 'Our Services',
    'services.net.title': '.NET Development',
    'services.net.description': 'Expert .NET development for robust, scalable applications',
    'services.react.title': 'React Development',
    'services.react.description': 'Creating dynamic and responsive user interfaces with React',
    'services.angular.title': 'Angular Development',
    'services.angular.description': 'Building powerful single-page applications with Angular',
    'services.database.title': 'Database Design',
    'services.database.description': 'Efficient and secure database solutions for your data needs',
    'services.backend.title': 'Backend Development',
    'services.backend.description': 'Solid backend architectures to power your applications',
    'services.wordpress.title': 'WordPress Development',
    'services.wordpress.description': 'Custom WordPress themes and plugins for your unique needs',
    'services.custom.title': 'Custom Solutions',
    'services.custom.description': 'Tailored software solutions to meet your unique requirements',
    'services.calculator.title': 'Project Cost Calculator',
    'services.calculator.projectType': 'Project Type',
    'services.calculator.selectType': 'Select project type',
    'services.calculator.webApp': 'Web Application',
    'services.calculator.mobileApp': 'Mobile Application',
    'services.calculator.desktopApp': 'Desktop Application',
    'services.calculator.wordpressWebsite': 'WordPress Website',
    'services.calculator.projectSize': 'Project Size',
    'services.calculator.selectSize': 'Select project size',
    'services.calculator.small': 'Small (1-3 months)',
    'services.calculator.medium': 'Medium (3-6 months)',
    'services.calculator.large': 'Large (6+ months)',
    'services.calculator.complexity': 'Project Complexity',
    'services.calculator.selectComplexity': 'Select complexity',
    'services.calculator.low': 'Low',
    'services.calculator.medium': 'Medium',
    'services.calculator.high': 'High',
    'services.calculator.designRequirement': 'Design Requirement',
    'services.calculator.selectDesign': 'Select design requirement',
    'services.calculator.basic': 'Basic',
    'services.calculator.custom': 'Custom',
    'services.calculator.premium': 'Premium',
    'services.calculator.calculateButton': 'Calculate Estimate',
    'services.calculator.estimatedCost': 'Estimated Cost: ${cost}',
    'services.calculator.contactButton': 'Contact Us for Details',
    'about.pageTitle': 'About KodikoLab',
    'about.story.title': 'Our Story',
    'about.story.paragraph1': 'Founded in October 2024, KodikoLab emerged from a passion for crafting exceptional digital solutions. Our team of seasoned developers and creative minds came together with a shared vision: to transform ideas into powerful, efficient, and innovative software applications.',
    'about.story.paragraph2': 'Since our inception, weve been on a mission to elevate the standard of software development. We believe that great code is not just about functionality—its about creating elegant solutions that drive business growth and user satisfaction.',
    'about.mission.title': 'Our Mission',
    'about.mission.paragraph1': 'At KodikoLab, our mission is to empower businesses through transformative digital solutions. We strive to create software that not only meets but exceeds our clients expectations, fostering innovation and driving success in the digital age.',
    'about.mission.paragraph2': 'We are committed to delivering excellence in every line of code, every user interface, and every digital experience we create. Our goal is to be at the forefront of technological advancement, continuously pushing the boundaries of whats possible in software development.',
    'about.values.title': 'Our Values',
    'about.values.description': 'At the heart of KodikoLab are the values that guide our work and shape our culture. These principles are the foundation of our success and the driving force behind our commitment to excellence.',
    'about.values.codeExcellence.title': 'Code Excellence',
    'about.values.codeExcellence.description': 'We are committed to writing clean, efficient, and maintainable code that stands the test of time.',
    'about.values.innovation.title': 'Innovation',
    'about.values.innovation.description': 'We constantly explore new technologies and methodologies to deliver cutting-edge solutions.',
    'about.values.clientPartnership.title': 'Client Partnership',
    'about.values.clientPartnership.description': 'We believe in building strong, long-lasting relationships with our clients, becoming true partners in their success.',
    'about.values.continuousGrowth.title': 'Continuous Growth',
    'about.values.continuousGrowth.description': 'We are dedicated to continuous learning and improvement, staying ahead in the fast-paced world of technology.',
    'about.team.title': 'Our Team',
    'about.team.description1': 'KodikoLab is powered by a team of passionate and experienced professionals. With years of collective experience in diverse areas of software development, our experts bring a wealth of knowledge and creativity to every project.',
    'about.team.description2': 'From seasoned .NET developers to React and Angular specialists, database architects to UX/UI designers, our team is equipped to handle projects of any scale and complexity. We pride ourselves on our collaborative approach, combining our individual strengths to deliver holistic solutions that drive real results.',
    'about.expertise.title': 'Our Expertise',
    'about.expertise.introduction': 'At KodikoLab, we specialize in a wide range of technologies and services, including:',
    'about.expertise.list': '.NET Development, React and Angular Frontend Solutions, Database Design and Optimization, Custom Software Development, Web and Mobile Application Development, Cloud Solutions and Integration',
    'about.expertise.conclusion': 'Our versatility allows us to cater to diverse client needs, from startups looking for MVP development to large enterprises requiring complex, scalable solutions.',
    'about.commitment.title': 'Our Commitment',
    'about.commitment.paragraph1': 'At KodikoLab, were more than just a software development company—were your technology partner. Were committed to understanding your unique challenges and goals, and to delivering solutions that not only meet your current needs but also position you for future success.',
    'about.commitment.paragraph2': 'Whether youre a small business looking to establish your digital presence or a large corporation aiming to streamline operations through custom software, KodikoLab is here to turn your vision into reality. Join us on this exciting journey of digital transformation, and lets create something extraordinary together.',
    'contact.pageTitle': 'Get in Touch',
    'contact.infoTitle': 'We\'d Love to Hear from You',
    'contact.infoDescription': 'Ready to start your next project or have questions about our services? Reach out to us and let\'s create something amazing together!',
    'contact.email': 'info@kodikolab.com',
    'contact.phone': '+1 (555) 123-4567',
    'contact.address': '123 Tech Street, Innovation City, 12345',
    'contact.form.name': 'Name',
    'contact.form.email': 'Email',
    'contact.form.phone': 'Phone Number',
    'contact.form.subject': 'Subject',
    'contact.form.message': 'Message',
    'contact.form.send': 'Send Message',
    'contact.form.sending': 'Sending...',
    'contact.form.successMessage': 'Thank you for your message! We\'ll get back to you soon.',
    'contact.form.errorMessage': 'Oops! Something went wrong. Please try again later.',
  },
  [LOCALES.POLISH]: {
    'app.title': 'Kodikolab',
    'nav.home': 'Strona główna',
    'nav.projects': 'Projekty',
    'nav.services': 'Usługi',
    'nav.about': 'O nas',
    'nav.contact': 'Kontakt',
    'banner.title': 'Przekształć swoją wizję z firmą {company}',
    'banner.subtitle': 'Innowacyjne rozwiązania programistyczne dla ery cyfrowej',
    'banner.cta': 'Rozpal swoją innowacyjność',
    'feature.software': 'Tworzenie oprogramowania',
    'feature.mobile': 'Aplikacje mobilne',
    'feature.cloud': 'Rozwiązania chmurowe',
    'feature.automation': 'Automatyzacja',
    'stats.clients': 'Zadowolonych klientów',
    'stats.projects': 'Zrealizowanych projektów',
    'stats.satisfaction': 'Satysfakcja klientów',
    'why.sectionTitle': 'Dlaczego warto wybrać Kodikolab?',
    'why.expertTeam.title': 'Zespół ekspertów',
    'why.expertTeam.description': 'Wykwalifikowani specjaliści zaangażowani w Twój sukces',
    'why.innovativeSolutions.title': 'Innowacyjne rozwiązania',
    'why.innovativeSolutions.description': 'Nowoczesne technologie dla współczesnych wyzwań',
    'why.clientFocused.title': 'Podejście zorientowane na klienta',
    'why.clientFocused.description': 'Twoja wizja, nasza misja',
    'why.leadingTechnology.title': 'Wiodąca technologia',
    'why.leadingTechnology.description': 'Bądź na czele z najnowszymi osiągnięciami technologicznymi',
    'projects.sectionTitle': 'Ostatnie Projekty',
    'projects.random.name': 'Innowacyjne Rozwiązanie Technologiczne',
    'projects.random.description': 'Projekt wykorzystujący najnowsze technologie do rozwiązywania złożonych wyzwań biznesowych.',
    'projects.status.inProgress': 'W trakcie realizacji',
    'projects.random.client': 'Innowatorzy Technologii Sp. z o.o.',
    'projects.fetchError': 'Nie udało się pobrać ostatnich projektów',
    'projects.loading': 'Ładowanie ostatnich projektów',
    'projects.imageAlt': 'projekt',
    'projects.status': 'Status',
    'projects.client': 'Klient',
    'projects.viewAll': 'Zobacz Wszystkie Projekty',
    'news.sectionTitle': 'Najnowsze Wiadomości i Nadchodzące Wydarzenia',
    'news.ai.title': 'Kodikolab uruchamia nową usługę AI',
    'news.ai.description': 'Rewolucjonizujemy procesy biznesowe za pomocą najnowocześniejszych rozwiązań AI',
    'news.webinar.title': 'Nadchodzący webinar: Przyszłość Rozwoju Stron Internetowych',
    'news.webinar.description': 'Dołącz do nas 15 czerwca, aby poznać najnowsze technologie webowe',
    'news.hiring.title': 'Zatrudniamy: Starszy Programista React',
    'news.hiring.description': 'Dołącz do naszego innowacyjnego zespołu i kształtuj przyszłość aplikacji internetowych',
    'news.readMore': 'Czytaj Więcej',
    'footer.company': 'Kodikolab',
    'footer.slogan': 'Innowacyjne rozwiązania dla Twoich potrzeb cyfrowych.',
    'footer.social.facebook': 'Facebook',
    'footer.social.twitter': 'Twitter',
    'footer.social.linkedin': 'LinkedIn',
    'footer.social.instagram': 'Instagram',
    'footer.quickLinks.title': 'Szybkie Linki',
    'footer.quickLinks.home': 'Strona główna',
    'footer.quickLinks.projects': 'Projekty',
    'footer.quickLinks.about': 'O nas',
    'footer.quickLinks.contact': 'Kontakt',
    'footer.contact.title': 'Skontaktuj się z nami',
    'footer.contact.email': 'info@kodikolab.com',
    'footer.contact.phone': '+48 123 456 789',
    'footer.contact.address': 'ul. Technologiczna 123, 00-000 Warszawa',
    'footer.copyright': '© {year} Kodikolab. Wszelkie prawa zastrzeżone.',
    'projects.pageTitle': 'Nasze Projekty',
    'projects.searchPlaceholder': 'Szukaj projektów...',
    'projects.filterButton': 'Filtruj',
    'projects.loading': 'Ładowanie projektów',
    'projects.imageAlt': 'projekt',
    'projects.statusLabel': 'Status',
    'projects.clientLabel': 'Klient',
    'projects.fetchError': 'Nie udało się pobrać projektów',
    'projects.loadError': 'Nie udało się załadować projektów. Wyświetlanie przykładowych danych.',
    'projects.filter.all': 'Wszystkie',
    'projects.status.inProgress': 'W trakcie',
    'projects.status.completed': 'Zakończony',
    'projects.status.planning': 'Planowanie',
    'projects.client.techInnovators': 'Tech Innovators Sp. z o.o.',
    'projects.client.globalSolutions': 'Global Solutions Sp. z o.o.',
    'projects.client.futureSystems': 'Future Systems S.A.',
    'projects.client.digitalDynamics': 'Digital Dynamics Sp. j.',
    'projects.client.ecoTech': 'EcoTech Solutions Sp. z o.o.',
    'projects.client.smartInnovations': 'Smart Innovations S.A.',
    'projects.type.webDev': 'Rozwój Stron Internetowych',
    'projects.type.mobileApp': 'Aplikacja Mobilna',
    'projects.type.cloudSolution': 'Rozwiązanie Chmurowe',
    'projects.type.aiIntegration': 'Integracja AI',
    'projects.type.iotPlatform': 'Platforma IoT',
    'projects.type.blockchain': 'Implementacja Blockchain',
    'projects.description': 'Innowacyjny projekt {type} wykorzystujący najnowsze technologie do rozwiązywania złożonych wyzwań biznesowych. Ten projekt ma na celu zrewolucjonizowanie branży dzięki unikalnemu podejściu i zaawansowanym funkcjom.',
    'services.pageTitle': 'Nasze Usługi',
    'services.net.title': 'Rozwój .NET',
    'services.net.description': 'Ekspercki rozwój .NET dla solidnych, skalowalnych aplikacji',
    'services.react.title': 'Rozwój React',
    'services.react.description': 'Tworzenie dynamicznych i responsywnych interfejsów użytkownika z React',
    'services.angular.title': 'Rozwój Angular',
    'services.angular.description': 'Budowanie zaawansowanych aplikacji jednostronicowych z Angular',
    'services.database.title': 'Projektowanie Baz Danych',
    'services.database.description': 'Wydajne i bezpieczne rozwiązania bazodanowe dla Twoich potrzeb',
    'services.backend.title': 'Rozwój Backend',
    'services.backend.description': 'Solidne architektury backendowe do zasilania Twoich aplikacji',
    'services.wordpress.title': 'Rozwój WordPress',
    'services.wordpress.description': 'Niestandardowe motywy i wtyczki WordPress dla Twoich unikalnych potrzeb',
    'services.custom.title': 'Rozwiązania Niestandardowe',
    'services.custom.description': 'Dostosowane rozwiązania programowe spełniające Twoje unikalne wymagania',
    'services.calculator.title': 'Kalkulator Kosztów Projektu',
    'services.calculator.projectType': 'Typ Projektu',
    'services.calculator.selectType': 'Wybierz typ projektu',
    'services.calculator.webApp': 'Aplikacja Webowa',
    'services.calculator.mobileApp': 'Aplikacja Mobilna',
    'services.calculator.desktopApp': 'Aplikacja Desktopowa',
    'services.calculator.wordpressWebsite': 'Strona WordPress',
    'services.calculator.projectSize': 'Rozmiar Projektu',
    'services.calculator.selectSize': 'Wybierz rozmiar projektu',
    'services.calculator.small': 'Mały (1-3 miesiące)',
    'services.calculator.medium': 'Średni (3-6 miesięcy)',
    'services.calculator.large': 'Duży (6+ miesięcy)',
    'services.calculator.complexity': 'Złożoność Projektu',
    'services.calculator.selectComplexity': 'Wybierz złożoność',
    'services.calculator.low': 'Niska',
    'services.calculator.medium': 'Średnia',
    'services.calculator.high': 'Wysoka',
    'services.calculator.designRequirement': 'Wymagania Projektowe',
    'services.calculator.selectDesign': 'Wybierz wymagania projektowe',
    'services.calculator.basic': 'Podstawowe',
    'services.calculator.custom': 'Niestandardowe',
    'services.calculator.premium': 'Premium',
    'services.calculator.calculateButton': 'Oblicz Szacunek',
    'services.calculator.estimatedCost': 'Szacowany Koszt: {cost} zł',
    'services.calculator.contactButton': 'Skontaktuj się z nami po szczegóły',
    'about.pageTitle': 'O KodikoLab',
    'about.story.title': 'Nasza Historia',
    'about.story.paragraph1': 'Założona w październiku 2024 roku, firma KodikoLab powstała z pasji do tworzenia wyjątkowych rozwiązań cyfrowych. Nasz zespół doświadczonych programistów i kreatywnych umysłów połączył się ze wspólną wizją: przekształcania pomysłów w wydajne, innowacyjne aplikacje.',
    'about.story.paragraph2': 'Od początku naszej działalności dążymy do podnoszenia standardów rozwoju oprogramowania. Wierzymy, że dobry kod to nie tylko funkcjonalność - to tworzenie eleganckich rozwiązań, które napędzają rozwój biznesu i satysfakcję użytkowników.',
    'about.mission.title': 'Nasza Misja',
    'about.mission.paragraph1': 'Misją KodikoLab jest wzmacnianie firm poprzez transformacyjne rozwiązania cyfrowe. Dążymy do tworzenia oprogramowania, które nie tylko spełnia, ale przewyższa oczekiwania naszych klientów, wspierając innowacje i napędzając sukces w erze cyfrowej.',
    'about.mission.paragraph2': 'Jesteśmy zaangażowani w dostarczanie doskonałości w każdej linii kodu, każdym interfejsie użytkownika i każdym tworzonym przez nas doświadczeniu cyfrowym. Naszym celem jest bycie na czele postępu technologicznego, ciągłe przesuwanie granic możliwości w rozwoju oprogramowania.',
    'about.values.title': 'Nasze Wartości',
    'about.values.description': 'Sercem KodikoLab są wartości, które kierują naszą pracą i kształtują naszą kulturę. Te zasady są fundamentem naszego sukcesu i siłą napędową naszego zaangażowania w doskonałość.',
    'about.values.codeExcellence.title': 'Doskonałość Kodu',
    'about.values.codeExcellence.description': 'Jesteśmy zaangażowani w pisanie czystego, wydajnego i łatwego w utrzymaniu kodu, który przetrwa próbę czasu.',
    'about.values.innovation.title': 'Innowacja',
    'about.values.innovation.description': 'Stale badamy nowe technologie i metodologie, aby dostarczać najnowocześniejsze rozwiązania.',
    'about.values.clientPartnership.title': 'Partnerstwo z Klientem',
    'about.values.clientPartnership.description': 'Wierzymy w budowanie silnych, długotrwałych relacji z naszymi klientami, stając się prawdziwymi partnerami w ich sukcesie.',
    'about.values.continuousGrowth.title': 'Ciągły Rozwój',
    'about.values.continuousGrowth.description': 'Jesteśmy oddani ciągłemu uczeniu się i doskonaleniu, pozostając na czele w szybko zmieniającym się świecie technologii.',
    'about.team.title': 'Nasz Zespół',
    'about.team.description1': 'KodikoLab jest napędzane przez zespół pełnych pasji i doświadczonych profesjonalistów. Z latami zbiorowego doświadczenia w różnych obszarach rozwoju oprogramowania, nasi eksperci wnoszą bogactwo wiedzy i kreatywności do każdego projektu.',
    'about.team.description2': 'Od doświadczonych programistów .NET po specjalistów React i Angular, od architektów baz danych po projektantów UX/UI, nasz zespół jest przygotowany do obsługi projektów o każdej skali i złożoności. Jesteśmy dumni z naszego podejścia opartego na współpracy, łącząc nasze indywidualne mocne strony, aby dostarczać kompleksowe rozwiązania, które przynoszą realne wyniki.',
    'about.expertise.title': 'Nasza Ekspertyza',
    'about.expertise.introduction': 'W KodikoLab specjalizujemy się w szerokim zakresie technologii i usług, w tym:',
    'about.expertise.list': 'Rozwój .NET, Rozwiązania frontendowe React i Angular, Projektowanie i optymalizacja baz danych, Tworzenie oprogramowania na zamówienie, Tworzenie aplikacji internetowych i mobilnych, Rozwiązania i integracja chmury',
    'about.expertise.conclusion': 'Nasza wszechstronność pozwala nam zaspokoić różnorodne potrzeby klientów, od startupów poszukujących rozwoju MVP po duże przedsiębiorstwa wymagające złożonych, skalowalnych rozwiązań.',
    'about.commitment.title': 'Nasze Zobowiązanie',
    'about.commitment.paragraph1': 'W KodikoLab jesteśmy czymś więcej niż tylko firmą zajmującą się tworzeniem oprogramowania - jesteśmy Twoim partnerem technologicznym. Jesteśmy zaangażowani w zrozumienie Twoich unikalnych wyzwań i celów oraz dostarczanie rozwiązań, które nie tylko spełniają Twoje obecne potrzeby, ale także przygotowują Cię na przyszły sukces.',
    'about.commitment.paragraph2': 'Niezależnie od tego, czy jesteś małą firmą chcącą zaznaczyć swoją obecność w świecie cyfrowym, czy dużą korporacją dążącą do usprawnienia operacji poprzez niestandardowe oprogramowanie, KodikoLab jest tutaj, aby przekształcić Twoją wizję w rzeczywistość. Dołącz do nas w tej ekscytującej podróży cyfrowej transformacji i stwórzmy razem coś niezwykłego.',
    'contact.pageTitle': 'Skontaktuj się z nami',
    'contact.infoTitle': 'Chcielibyśmy usłyszeć od Ciebie',
    'contact.infoDescription': 'Gotowy na rozpoczęcie nowego projektu lub masz pytania dotyczące naszych usług? Skontaktuj się z nami i stwórzmy razem coś niesamowitego!',
    'contact.email': 'info@kodikolab.com',
    'contact.phone': '+48 555 123 456',
    'contact.address': 'ul. Technologiczna 123, 00-001 Warszawa',
    'contact.form.name': 'Imię i nazwisko',
    'contact.form.email': 'Email',
    'contact.form.phone': 'Numer telefonu',
    'contact.form.subject': 'Temat',
    'contact.form.message': 'Wiadomość',
    'contact.form.send': 'Wyślij wiadomość',
    'contact.form.sending': 'Wysyłanie...',
    'contact.form.successMessage': 'Dziękujemy za Twoją wiadomość! Odezwiemy się wkrótce.',
    'contact.form.errorMessage': 'Ups! Coś poszło nie tak. Proszę spróbować ponownie później.',
  },
  [LOCALES.GERMAN]: {
    'app.title': 'Kodikolab',
    'nav.home': 'Startseite',
    'nav.projects': 'Projekte',
    'nav.services': 'Dienstleistungen',
    'nav.about': 'Über uns',
    'nav.contact': 'Kontakt',
    'banner.title': 'Verwirklichen Sie Ihre Vision mit {company}',
    'banner.subtitle': 'Zukunftsweisende Softwarelösungen für das digitale Zeitalter',
    'banner.cta': 'Entfachen Sie Ihre Innovation',
    'feature.software': 'Softwareentwicklung',
    'feature.mobile': 'Mobile Anwendungen',
    'feature.cloud': 'Cloud-Lösungen',
    'feature.automation': 'Automatisierung',
    'stats.clients': 'Zufriedene Kunden',
    'stats.projects': 'Abgeschlossene Projekte',
    'stats.satisfaction': 'Kundenzufriedenheit',
    'why.sectionTitle': 'Warum Kodikolab wählen?',
    'why.expertTeam.title': 'Expertenteam',
    'why.expertTeam.description': 'Qualifizierte Fachleute, die sich Ihrem Erfolg verschrieben haben',
    'why.innovativeSolutions.title': 'Innovative Lösungen',
    'why.innovativeSolutions.description': 'Modernste Technologie für aktuelle Herausforderungen',
    'why.clientFocused.title': 'Kundenorientierter Ansatz',
    'why.clientFocused.description': 'Ihre Vision, unsere Mission',
    'why.leadingTechnology.title': 'Führende Technologie',
    'why.leadingTechnology.description': 'Bleiben Sie mit den neuesten technologischen Fortschritten an der Spitze',
    'projects.sectionTitle': 'Aktuelle Projekte',
    'projects.random.name': 'Innovative Technologielösung',
    'projects.random.description': 'Ein hochmodernes Projekt, das die neuesten Technologien nutzt, um komplexe Geschäftsherausforderungen zu lösen.',
    'projects.status.inProgress': 'In Bearbeitung',
    'projects.random.client': 'Tech Innovatoren GmbH',
    'projects.fetchError': 'Fehler beim Abrufen der aktuellen Projekte',
    'projects.loading': 'Lade aktuelle Projekte',
    'projects.imageAlt': 'Projekt',
    'projects.status': 'Status',
    'projects.client': 'Kunde',
    'projects.viewAll': 'Alle Projekte ansehen',
    'news.sectionTitle': 'Aktuelle Nachrichten & Kommende Veranstaltungen',
    'news.ai.title': 'Kodikolab startet neuen KI-Dienst',
    'news.ai.description': 'Revolutionierung von Geschäftsprozessen mit modernsten KI-Lösungen',
    'news.webinar.title': 'Kommendes Webinar: Zukunft der Webentwicklung',
    'news.webinar.description': 'Nehmen Sie am 15. Juni teil und erhalten Sie Einblicke in neue Webtechnologien',
    'news.hiring.title': 'Wir stellen ein: Senior React Entwickler',
    'news.hiring.description': 'Werden Sie Teil unseres innovativen Teams und gestalten Sie die Zukunft von Webanwendungen',
    'news.readMore': 'Mehr Lesen',
    'footer.company': 'Kodikolab',
    'footer.slogan': 'Innovative Lösungen für Ihre digitalen Bedürfnisse.',
    'footer.social.facebook': 'Facebook',
    'footer.social.twitter': 'Twitter',
    'footer.social.linkedin': 'LinkedIn',
    'footer.social.instagram': 'Instagram',
    'footer.quickLinks.title': 'Schnellzugriff',
    'footer.quickLinks.home': 'Startseite',
    'footer.quickLinks.projects': 'Projekte',
    'footer.quickLinks.about': 'Über uns',
    'footer.quickLinks.contact': 'Kontakt',
    'footer.contact.title': 'Kontaktieren Sie uns',
    'footer.contact.email': 'info@kodikolab.com',
    'footer.contact.phone': '+49 123 456789',
    'footer.contact.address': 'Technikstraße 123, 10115 Berlin',
    'footer.copyright': '© {year} Kodikolab. Alle Rechte vorbehalten.',
    'projects.pageTitle': 'Unsere Projekte',
    'projects.searchPlaceholder': 'Projekte suchen...',
    'projects.filterButton': 'Filtern',
    'projects.loading': 'Projekte werden geladen',
    'projects.imageAlt': 'Projekt',
    'projects.statusLabel': 'Status',
    'projects.clientLabel': 'Kunde',
    'projects.fetchError': 'Fehler beim Abrufen der Projekte',
    'projects.loadError': 'Fehler beim Laden der Projekte. Beispieldaten werden angezeigt.',
    'projects.filter.all': 'Alle',
    'projects.status.inProgress': 'In Bearbeitung',
    'projects.status.completed': 'Abgeschlossen',
    'projects.status.planning': 'In Planung',
    'projects.client.techInnovators': 'Tech Innovators GmbH',
    'projects.client.globalSolutions': 'Global Solutions AG',
    'projects.client.futureSystems': 'Future Systems GmbH',
    'projects.client.digitalDynamics': 'Digital Dynamics OHG',
    'projects.client.ecoTech': 'EcoTech Solutions GmbH',
    'projects.client.smartInnovations': 'Smart Innovations AG',
    'projects.type.webDev': 'Webentwicklung',
    'projects.type.mobileApp': 'Mobile App',
    'projects.type.cloudSolution': 'Cloud-Lösung',
    'projects.type.aiIntegration': 'KI-Integration',
    'projects.type.iotPlatform': 'IoT-Plattform',
    'projects.type.blockchain': 'Blockchain-Implementierung',
    'projects.description': 'Ein hochmodernes {type}-Projekt, das die neuesten Technologien nutzt, um komplexe geschäftliche Herausforderungen zu lösen. Dieses Projekt zielt darauf ab, die Branche mit seinem einzigartigen Ansatz und fortschrittlichen Funktionen zu revolutionieren.',
    'services.pageTitle': 'Unsere Dienstleistungen',
    'services.net.title': '.NET-Entwicklung',
    'services.net.description': 'Expertenentwicklung in .NET für robuste, skalierbare Anwendungen',
    'services.react.title': 'React-Entwicklung',
    'services.react.description': 'Erstellung dynamischer und responsiver Benutzeroberflächen mit React',
    'services.angular.title': 'Angular-Entwicklung',
    'services.angular.description': 'Entwicklung leistungsstarker Einzelseitenanwendungen mit Angular',
    'services.database.title': 'Datenbankdesign',
    'services.database.description': 'Effiziente und sichere Datenbanklösungen für Ihre Datenanforderungen',
    'services.backend.title': 'Backend-Entwicklung',
    'services.backend.description': 'Solide Backend-Architekturen zur Unterstützung Ihrer Anwendungen',
    'services.wordpress.title': 'WordPress-Entwicklung',
    'services.wordpress.description': 'Maßgeschneiderte WordPress-Themes und Plugins für Ihre individuellen Bedürfnisse',
    'services.custom.title': 'Individuelle Lösungen',
    'services.custom.description': 'Maßgeschneiderte Softwarelösungen, um Ihre einzigartigen Anforderungen zu erfüllen',
    'services.calculator.title': 'Projektkostenrechner',
    'services.calculator.projectType': 'Projekttyp',
    'services.calculator.selectType': 'Projekttyp auswählen',
    'services.calculator.webApp': 'Web-Anwendung',
    'services.calculator.mobileApp': 'Mobile Anwendung',
    'services.calculator.desktopApp': 'Desktop-Anwendung',
    'services.calculator.wordpressWebsite': 'WordPress-Website',
    'services.calculator.projectSize': 'Projektumfang',
    'services.calculator.selectSize': 'Projektumfang auswählen',
    'services.calculator.small': 'Klein (1-3 Monate)',
    'services.calculator.medium': 'Mittel (3-6 Monate)',
    'services.calculator.large': 'Groß (6+ Monate)',
    'services.calculator.complexity': 'Projektkomplexität',
    'services.calculator.selectComplexity': 'Komplexität auswählen',
    'services.calculator.low': 'Niedrig',
    'services.calculator.medium': 'Mittel',
    'services.calculator.high': 'Hoch',
    'services.calculator.designRequirement': 'Design-Anforderung',
    'services.calculator.selectDesign': 'Design-Anforderung auswählen',
    'services.calculator.basic': 'Basis',
    'services.calculator.custom': 'Individuell',
    'services.calculator.premium': 'Premium',
    'services.calculator.calculateButton': 'Kostenvoranschlag berechnen',
    'services.calculator.estimatedCost': 'Geschätzte Kosten: {cost} €',
    'services.calculator.contactButton': 'Kontaktieren Sie uns für Details',
    'about.pageTitle': 'Über KodikoLab',
    'about.story.title': 'Unsere Geschichte',
    'about.story.paragraph1': 'KodikoLab wurde im Oktober 2024 aus der Leidenschaft für die Erstellung außergewöhnlicher digitaler Lösungen gegründet. Unser Team aus erfahrenen Entwicklern und kreativen Köpfen kam mit einer gemeinsamen Vision zusammen: Ideen in leistungsstarke, effiziente und innovative Softwareanwendungen zu verwandeln.',
    'about.story.paragraph2': 'Seit unserer Gründung haben wir es uns zur Aufgabe gemacht, den Standard der Softwareentwicklung zu erhöhen. Wir glauben, dass großartiger Code nicht nur funktional sein sollte - es geht darum, elegante Lösungen zu schaffen, die das Geschäftswachstum und die Benutzerzufriedenheit fördern.',
    'about.mission.title': 'Unsere Mission',
    'about.mission.paragraph1': 'Bei KodikoLab ist es unsere Mission, Unternehmen durch transformative digitale Lösungen zu stärken. Wir streben danach, Software zu entwickeln, die die Erwartungen unserer Kunden nicht nur erfüllt, sondern übertrifft, und dabei Innovation fördert und Erfolg im digitalen Zeitalter vorantreibt.',
    'about.mission.paragraph2': 'Wir sind bestrebt, in jeder Codezeile, jeder Benutzeroberfläche und jeder digitalen Erfahrung, die wir schaffen, Exzellenz zu liefern. Unser Ziel ist es, an der Spitze des technologischen Fortschritts zu stehen und kontinuierlich die Grenzen des in der Softwareentwicklung Möglichen zu erweitern.',
    'about.values.title': 'Unsere Werte',
    'about.values.description': 'Im Herzen von KodikoLab stehen die Werte, die unsere Arbeit leiten und unsere Kultur prägen. Diese Prinzipien sind das Fundament unseres Erfolgs und die treibende Kraft hinter unserem Engagement für Exzellenz.',
    'about.values.codeExcellence.title': 'Code-Exzellenz',
    'about.values.codeExcellence.description': 'Wir verpflichten uns, sauberen, effizienten und wartbaren Code zu schreiben, der die Zeit überdauert.',
    'about.values.innovation.title': 'Innovation',
    'about.values.innovation.description': 'Wir erforschen ständig neue Technologien und Methoden, um bahnbrechende Lösungen zu liefern.',
    'about.values.clientPartnership.title': 'Kundenpartnerschaft',
    'about.values.clientPartnership.description': 'Wir glauben an den Aufbau starker, langfristiger Beziehungen zu unseren Kunden und werden zu wahren Partnern in ihrem Erfolg.',
    'about.values.continuousGrowth.title': 'Kontinuierliches Wachstum',
    'about.values.continuousGrowth.description': 'Wir engagieren uns für kontinuierliches Lernen und Verbesserung und bleiben in der schnelllebigen Welt der Technologie stets an der Spitze.',
    'about.team.title': 'Unser Team',
    'about.team.description1': 'KodikoLab wird von einem Team leidenschaftlicher und erfahrener Fachleute angetrieben. Mit jahrelanger kollektiver Erfahrung in verschiedenen Bereichen der Softwareentwicklung bringen unsere Experten eine Fülle von Wissen und Kreativität in jedes Projekt ein.',
    'about.team.description2': 'Von erfahrenen .NET-Entwicklern bis hin zu React- und Angular-Spezialisten, von Datenbankarchitekten bis hin zu UX/UI-Designern ist unser Team in der Lage, Projekte jeder Größenordnung und Komplexität zu bewältigen. Wir sind stolz auf unseren kollaborativen Ansatz, bei dem wir unsere individuellen Stärken kombinieren, um ganzheitliche Lösungen zu liefern, die echte Ergebnisse erzielen.',
    'about.expertise.title': 'Unsere Expertise',
    'about.expertise.introduction': 'Bei KodikoLab sind wir auf ein breites Spektrum von Technologien und Dienstleistungen spezialisiert, darunter:',
    'about.expertise.list': '.NET-Entwicklung, React- und Angular-Frontend-Lösungen, Datenbankdesign und -optimierung, Individuelle Softwareentwicklung, Web- und Mobile-App-Entwicklung, Cloud-Lösungen und -Integration',
    'about.expertise.conclusion': 'Unsere Vielseitigkeit ermöglicht es uns, auf vielfältige Kundenbedürfnisse einzugehen, von Startups, die nach MVP-Entwicklung suchen, bis hin zu großen Unternehmen, die komplexe, skalierbare Lösungen benötigen.',
    'about.commitment.title': 'Unser Engagement',
    'about.commitment.paragraph1': 'Bei KodikoLab sind wir mehr als nur ein Softwareentwicklungsunternehmen - wir sind Ihr Technologiepartner. Wir setzen uns dafür ein, Ihre einzigartigen Herausforderungen und Ziele zu verstehen und Lösungen zu liefern, die nicht nur Ihre aktuellen Bedürfnisse erfüllen, sondern Sie auch für zukünftigen Erfolg positionieren.',
    'about.commitment.paragraph2': 'Ob Sie ein kleines Unternehmen sind, das seine digitale Präsenz aufbauen möchte, oder ein großes Unternehmen, das seine Abläufe durch individuelle Software optimieren will - KodikoLab ist hier, um Ihre Vision Wirklichkeit werden zu lassen. Begleiten Sie uns auf dieser spannenden Reise der digitalen Transformation und lassen Sie uns gemeinsam etwas Außergewöhnliches schaffen.',
    'contact.pageTitle': 'Kontaktieren Sie uns',
    'contact.infoTitle': 'Wir würden gerne von Ihnen hören',
    'contact.infoDescription': 'Bereit für Ihr nächstes Projekt oder haben Sie Fragen zu unseren Dienstleistungen? Kontaktieren Sie uns und lassen Sie uns gemeinsam etwas Großartiges schaffen!',
    'contact.email': 'info@kodikolab.com',
    'contact.phone': '+49 555 123 4567',
    'contact.address': 'Technikstraße 123, 10115 Berlin',
    'contact.form.name': 'Name',
    'contact.form.email': 'E-Mail',
    'contact.form.phone': 'Telefonnummer',
    'contact.form.subject': 'Betreff',
    'contact.form.message': 'Nachricht',
    'contact.form.send': 'Nachricht senden',
    'contact.form.sending': 'Wird gesendet...',
    'contact.form.successMessage': 'Vielen Dank für Ihre Nachricht! Wir werden uns bald bei Ihnen melden.',
    'contact.form.errorMessage': 'Hoppla! Etwas ist schief gelaufen. Bitte versuchen Sie es später erneut.',
  },
  [LOCALES.SPANISH]: {
    'app.title': 'Kodikolab',
    'nav.home': 'Inicio',
    'nav.projects': 'Proyectos',
    'nav.services': 'Servicios',
    'nav.about': 'Nosotros',
    'nav.contact': 'Contacto',
    'banner.title': 'Transforma tu visión con {company}',
    'banner.subtitle': 'Soluciones de software vanguardistas para la era digital',
    'banner.cta': 'Impulsa tu innovación',
    'feature.software': 'Desarrollo de software',
    'feature.mobile': 'Aplicaciones móviles',
    'feature.cloud': 'Soluciones en la nube',
    'feature.automation': 'Automatización',
    'stats.clients': 'Clientes satisfechos',
    'stats.projects': 'Proyectos completados',
    'stats.satisfaction': 'Satisfacción del cliente',
    'why.sectionTitle': '¿Por qué elegir Kodikolab?',
    'why.expertTeam.title': 'Equipo experto',
    'why.expertTeam.description': 'Profesionales calificados dedicados a su éxito',
    'why.innovativeSolutions.title': 'Soluciones innovadoras',
    'why.innovativeSolutions.description': 'Tecnología de vanguardia para desafíos modernos',
    'why.clientFocused.title': 'Enfoque centrado en el cliente',
    'why.clientFocused.description': 'Su visión, nuestra misión',
    'why.leadingTechnology.title': 'Tecnología líder',
    'why.leadingTechnology.description': 'Manténgase a la vanguardia con los últimos avances tecnológicos',
    'projects.sectionTitle': 'Proyectos Recientes',
    'projects.random.name': 'Solución Tecnológica Innovadora',
    'projects.random.description': 'Un proyecto de vanguardia que aprovecha las últimas tecnologías para resolver desafíos empresariales complejos.',
    'projects.status.inProgress': 'En Progreso',
    'projects.random.client': 'Innovadores Tecnológicos S.A.',
    'projects.fetchError': 'Error al obtener los proyectos recientes',
    'projects.loading': 'Cargando proyectos recientes',
    'projects.imageAlt': 'proyecto',
    'projects.status': 'Estado',
    'projects.client': 'Cliente',
    'projects.viewAll': 'Ver Todos los Proyectos',
    'news.sectionTitle': 'Últimas Noticias y Próximos Eventos',
    'news.ai.title': 'Kodikolab lanza nuevo servicio de IA',
    'news.ai.description': 'Revolucionando los procesos empresariales con soluciones de IA de vanguardia',
    'news.webinar.title': 'Próximo webinar: El futuro del desarrollo web',
    'news.webinar.description': 'Únase a nosotros el 15 de junio para conocer las tecnologías web emergentes',
    'news.hiring.title': 'Estamos contratando: Desarrollador Senior de React',
    'news.hiring.description': 'Sea parte de nuestro equipo innovador y dé forma al futuro de las aplicaciones web',
    'news.readMore': 'Leer Más',
    'footer.company': 'Kodikolab',
    'footer.slogan': 'Soluciones innovadoras para sus necesidades digitales.',
    'footer.social.facebook': 'Facebook',
    'footer.social.twitter': 'Twitter',
    'footer.social.linkedin': 'LinkedIn',
    'footer.social.instagram': 'Instagram',
    'footer.quickLinks.title': 'Enlaces Rápidos',
    'footer.quickLinks.home': 'Inicio',
    'footer.quickLinks.projects': 'Proyectos',
    'footer.quickLinks.about': 'Acerca de',
    'footer.quickLinks.contact': 'Contacto',
    'footer.contact.title': 'Contáctenos',
    'footer.contact.email': 'info@kodikolab.com',
    'footer.contact.phone': '+34 123 456 789',
    'footer.contact.address': 'Calle Tecnología 123, 28001 Madrid',
    'footer.copyright': '© {year} Kodikolab. Todos los derechos reservados.',
    'projects.pageTitle': 'Nuestros Proyectos',
    'projects.searchPlaceholder': 'Buscar proyectos...',
    'projects.filterButton': 'Filtrar',
    'projects.loading': 'Cargando proyectos',
    'projects.imageAlt': 'proyecto',
    'projects.statusLabel': 'Estado',
    'projects.clientLabel': 'Cliente',
    'projects.fetchError': 'Error al obtener los proyectos',
    'projects.loadError': 'Error al cargar los proyectos. Mostrando datos de muestra.',
    'projects.filter.all': 'Todos',
    'projects.status.inProgress': 'En Progreso',
    'projects.status.completed': 'Completado',
    'projects.status.planning': 'En Planificación',
    'projects.client.techInnovators': 'Tech Innovators S.A.',
    'projects.client.globalSolutions': 'Soluciones Globales S.L.',
    'projects.client.futureSystems': 'Sistemas del Futuro S.A.',
    'projects.client.digitalDynamics': 'Dinámica Digital S.L.',
    'projects.client.ecoTech': 'EcoTech Soluciones S.A.',
    'projects.client.smartInnovations': 'Innovaciones Inteligentes S.L.',
    'projects.type.webDev': 'Desarrollo Web',
    'projects.type.mobileApp': 'Aplicación Móvil',
    'projects.type.cloudSolution': 'Solución en la Nube',
    'projects.type.aiIntegration': 'Integración de IA',
    'projects.type.iotPlatform': 'Plataforma IoT',
    'projects.type.blockchain': 'Implementación de Blockchain',
    'projects.description': 'Un proyecto de {type} de vanguardia que aprovecha las últimas tecnologías para resolver complejos desafíos empresariales. Este proyecto tiene como objetivo revolucionar la industria con su enfoque único y características avanzadas.',
    'services.pageTitle': 'Nuestros Servicios',
    'services.net.title': 'Desarrollo .NET',
    'services.net.description': 'Desarrollo experto en .NET para aplicaciones robustas y escalables',
    'services.react.title': 'Desarrollo React',
    'services.react.description': 'Creación de interfaces de usuario dinámicas y responsivas con React',
    'services.angular.title': 'Desarrollo Angular',
    'services.angular.description': 'Construcción de potentes aplicaciones de una sola página con Angular',
    'services.database.title': 'Diseño de Bases de Datos',
    'services.database.description': 'Soluciones de bases de datos eficientes y seguras para sus necesidades de datos',
    'services.backend.title': 'Desarrollo Backend',
    'services.backend.description': 'Arquitecturas backend sólidas para potenciar sus aplicaciones',
    'services.wordpress.title': 'Desarrollo WordPress',
    'services.wordpress.description': 'Temas y plugins de WordPress personalizados para sus necesidades únicas',
    'services.custom.title': 'Soluciones Personalizadas',
    'services.custom.description': 'Soluciones de software a medida para satisfacer sus requisitos únicos',
    'services.calculator.title': 'Calculadora de Costos de Proyecto',
    'services.calculator.projectType': 'Tipo de Proyecto',
    'services.calculator.selectType': 'Seleccione el tipo de proyecto',
    'services.calculator.webApp': 'Aplicación Web',
    'services.calculator.mobileApp': 'Aplicación Móvil',
    'services.calculator.desktopApp': 'Aplicación de Escritorio',
    'services.calculator.wordpressWebsite': 'Sitio Web WordPress',
    'services.calculator.projectSize': 'Tamaño del Proyecto',
    'services.calculator.selectSize': 'Seleccione el tamaño del proyecto',
    'services.calculator.small': 'Pequeño (1-3 meses)',
    'services.calculator.medium': 'Mediano (3-6 meses)',
    'services.calculator.large': 'Grande (6+ meses)',
    'services.calculator.complexity': 'Complejidad del Proyecto',
    'services.calculator.selectComplexity': 'Seleccione la complejidad',
    'services.calculator.low': 'Baja',
    'services.calculator.medium': 'Media',
    'services.calculator.high': 'Alta',
    'services.calculator.designRequirement': 'Requisito de Diseño',
    'services.calculator.selectDesign': 'Seleccione el requisito de diseño',
    'services.calculator.basic': 'Básico',
    'services.calculator.custom': 'Personalizado',
    'services.calculator.premium': 'Premium',
    'services.calculator.calculateButton': 'Calcular Estimación',
    'services.calculator.estimatedCost': 'Costo Estimado: {cost} €',
    'services.calculator.contactButton': 'Contáctenos para más detalles',
    'about.pageTitle': 'Sobre KodikoLab',
    'about.story.title': 'Nuestra Historia',
    'about.story.paragraph1': 'Fundada en octubre de 2024, KodikoLab surgió de la pasión por crear soluciones digitales excepcionales. Nuestro equipo de desarrolladores experimentados y mentes creativas se unió con una visión compartida: transformar ideas en aplicaciones de software potentes, eficientes e innovadoras.',
    'about.story.paragraph2': 'Desde nuestro inicio, hemos estado en una misión para elevar el estándar del desarrollo de software. Creemos que un gran código no se trata solo de funcionalidad, se trata de crear soluciones elegantes que impulsen el crecimiento del negocio y la satisfacción del usuario.',
    'about.mission.title': 'Nuestra Misión',
    'about.mission.paragraph1': 'En KodikoLab, nuestra misión es empoderar a las empresas a través de soluciones digitales transformadoras. Nos esforzamos por crear software que no solo cumpla, sino que supere las expectativas de nuestros clientes, fomentando la innovación e impulsando el éxito en la era digital.',
    'about.mission.paragraph2': 'Estamos comprometidos a entregar excelencia en cada línea de código, cada interfaz de usuario y cada experiencia digital que creamos. Nuestro objetivo es estar a la vanguardia del avance tecnológico, empujando continuamente los límites de lo posible en el desarrollo de software.',
    'about.values.title': 'Nuestros Valores',
    'about.values.description': 'En el corazón de KodikoLab están los valores que guían nuestro trabajo y dan forma a nuestra cultura. Estos principios son la base de nuestro éxito y la fuerza impulsora detrás de nuestro compromiso con la excelencia.',
    'about.values.codeExcellence.title': 'Excelencia en el Código',
    'about.values.codeExcellence.description': 'Estamos comprometidos a escribir código limpio, eficiente y mantenible que resista la prueba del tiempo.',
    'about.values.innovation.title': 'Innovación',
    'about.values.innovation.description': 'Exploramos constantemente nuevas tecnologías y metodologías para ofrecer soluciones de vanguardia.',
    'about.values.clientPartnership.title': 'Asociación con el Cliente',
    'about.values.clientPartnership.description': 'Creemos en construir relaciones sólidas y duraderas con nuestros clientes, convirtiéndonos en verdaderos socios en su éxito.',
    'about.values.continuousGrowth.title': 'Crecimiento Continuo',
    'about.values.continuousGrowth.description': 'Estamos dedicados al aprendizaje y mejora continuos, manteniéndonos a la vanguardia en el mundo de rápida evolución de la tecnología.',
    'about.team.title': 'Nuestro Equipo',
    'about.team.description1': 'KodikoLab está impulsado por un equipo de profesionales apasionados y experimentados. Con años de experiencia colectiva en diversas áreas del desarrollo de software, nuestros expertos aportan una gran cantidad de conocimientos y creatividad a cada proyecto.',
    'about.team.description2': 'Desde desarrolladores .NET experimentados hasta especialistas en React y Angular, desde arquitectos de bases de datos hasta diseñadores de UX/UI, nuestro equipo está equipado para manejar proyectos de cualquier escala y complejidad. Nos enorgullecemos de nuestro enfoque colaborativo, combinando nuestras fortalezas individuales para ofrecer soluciones holísticas que impulsan resultados reales.',
    'about.expertise.title': 'Nuestra Experiencia',
    'about.expertise.introduction': 'En KodikoLab, nos especializamos en una amplia gama de tecnologías y servicios, incluyendo:',
    'about.expertise.list': 'Desarrollo .NET, Soluciones Frontend con React y Angular, Diseño y Optimización de Bases de Datos, Desarrollo de Software Personalizado, Desarrollo de Aplicaciones Web y Móviles, Soluciones e Integración en la Nube',
    'about.expertise.conclusion': 'Nuestra versatilidad nos permite atender diversas necesidades de los clientes, desde startups que buscan desarrollo de MVP hasta grandes empresas que requieren soluciones complejas y escalables.',
    'about.commitment.title': 'Nuestro Compromiso',
    'about.commitment.paragraph1': 'En KodikoLab, somos más que solo una empresa de desarrollo de software, somos su socio tecnológico. Estamos comprometidos a entender sus desafíos y objetivos únicos, y a ofrecer soluciones que no solo satisfagan sus necesidades actuales, sino que también lo posicionen para el éxito futuro.',
    'about.commitment.paragraph2': 'Ya sea que sea una pequeña empresa que busca establecer su presencia digital o una gran corporación que busca optimizar operaciones a través de software personalizado, KodikoLab está aquí para convertir su visión en realidad. Únase a nosotros en este emocionante viaje de transformación digital y creemos algo extraordinario juntos.',
    'contact.pageTitle': 'Póngase en contacto',
    'contact.infoTitle': 'Nos encantaría saber de usted',
    'contact.infoDescription': '¿Listo para comenzar su próximo proyecto o tiene preguntas sobre nuestros servicios? ¡Contáctenos y creemos algo increíble juntos!',
    'contact.email': 'info@kodikolab.com',
    'contact.phone': '+34 555 123 456',
    'contact.address': 'Calle Tecnología 123, 28001 Madrid',
    'contact.form.name': 'Nombre',
    'contact.form.email': 'Correo electrónico',
    'contact.form.phone': 'Número de teléfono',
    'contact.form.subject': 'Asunto',
    'contact.form.message': 'Mensaje',
    'contact.form.send': 'Enviar mensaje',
    'contact.form.sending': 'Enviando...',
    'contact.form.successMessage': '¡Gracias por su mensaje! Nos pondremos en contacto con usted pronto.',
    'contact.form.errorMessage': '¡Ups! Algo salió mal. Por favor, inténtelo de nuevo más tarde.',
  },
};