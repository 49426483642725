import React from 'react';
import { useIntl } from 'react-intl';
import styled, { keyframes } from 'styled-components';
import { FaNewspaper, FaCalendarAlt, FaUserPlus, FaArrowRight } from 'react-icons/fa';

const fadeInUp = keyframes`
  from { opacity: 0; transform: translateY(30px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Section = styled.section`
  background: linear-gradient(180deg, #2a5298, #1e3c72);
  color: #ffffff;
  padding: 100px 0;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  margin-bottom: 3rem;
  animation: ${fadeInUp} 1s ease-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-weight: 700;
`;

const NewsList = styled.ul`
  list-style-type: none;
  padding: 0;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const NewsItem = styled.li`
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  padding: 2rem;
  transition: all 0.4s ease;
  animation: ${fadeInUp} 1s ease-out;
  animation-fill-mode: both;
  animation-delay: ${props => props.delay}s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-10px) scale(1.05);
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
`;

const NewsIcon = styled.div`
  font-size: 2.5rem;
  color: #7efff5;
  margin-bottom: 1rem;
`;

const NewsTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #ffffff;
  font-weight: 600;
`;

const NewsDescription = styled.p`
  font-size: 1rem;
  opacity: 0.95;
  line-height: 1.6;
  color: #f0f0f0;
`;

const ReadMoreButton = styled.button`
  background-color: #7efff5;
  color: #1e3c72;
  padding: 15px 40px;
  font-size: 1.2rem;
  font-weight: 700;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.4s ease;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover, &:focus {
    background-color: #ffffff;
    color: #4a00e0;
    transform: translateY(-5px) scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
    outline: none;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(126, 255, 245, 0.5), 0 15px 30px rgba(0, 0, 0, 0.3);
  }

  svg {
    margin-left: 10px;
  }
`;

const NewsEvents = () => {
  const intl = useIntl();

  const newsItems = [
    {
      icon: <FaNewspaper />,
      titleId: 'news.ai.title',
      descriptionId: 'news.ai.description'
    },
    {
      icon: <FaCalendarAlt />,
      titleId: 'news.webinar.title',
      descriptionId: 'news.webinar.description'
    },
    {
      icon: <FaUserPlus />,
      titleId: 'news.hiring.title',
      descriptionId: 'news.hiring.description'
    }
  ];

  return (
    <Section>
      <SectionTitle>{intl.formatMessage({ id: 'news.sectionTitle' })}</SectionTitle>
      <NewsList>
        {newsItems.map((item, index) => (
          <NewsItem key={index} delay={0.2 * (index + 1)}>
            <NewsIcon aria-hidden="true">{item.icon}</NewsIcon>
            <NewsTitle>{intl.formatMessage({ id: item.titleId })}</NewsTitle>
            <NewsDescription>{intl.formatMessage({ id: item.descriptionId })}</NewsDescription>
          </NewsItem>
        ))}
      </NewsList>
      <ReadMoreButton>
        {intl.formatMessage({ id: 'news.readMore' })} <FaArrowRight aria-hidden="true" />
      </ReadMoreButton>
    </Section>
  );
};

export default NewsEvents;