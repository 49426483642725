import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/Header';
import HomePage from './components/HomePage';
import ProjectsPage from './components/ProjectsPage';
import ServicesPage from './components/ServicesPage';
import AboutPage from './components/AboutPage';
import ContactPage from './components/ContactPage';
import Footer from './components/Footer';
import { LOCALES } from './i18n/locales';
import { messages } from './i18n/messages';
import './App.css';

function App() {
  const [currentLocale, setCurrentLocale] = useState(LOCALES.ENGLISH);

  return (
    <HelmetProvider>
      <IntlProvider messages={messages[currentLocale]} locale={currentLocale}>
        <Router>
          <div className="App">
            <Header 
              setCurrentLocale={setCurrentLocale} 
              currentLocale={currentLocale} 
            />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/projects" element={<ProjectsPage />} />
              <Route path="/services" element={<ServicesPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
            <Footer />
          </div>
        </Router>
      </IntlProvider>
    </HelmetProvider>
  );
}

export default App;