import React from 'react';

const ProjectCounter = ({ stats }) => {
  return (
    <section className="project-counter">
      <div>
        <h3>Total Projects</h3>
        <span>{stats.totalProjects}</span>
      </div>
      <div>
        <h3>Completed Projects</h3>
        <span>{stats.completedProjects}</span>
      </div>
      <div>
        <h3>Average Budget</h3>
        <span>${stats.averageBudget.toFixed(2)}k</span>
      </div>
      <div>
        <h3>Avg. Project Duration</h3>
        <span>{stats.averageProjectDuration.toFixed(0)} days</span>
      </div>
    </section>
  );
};

export default ProjectCounter;