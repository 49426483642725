import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled, { keyframes } from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa';
import LanguageSelector from './LanguageSelector';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  background: ${props => props.$isScrolled 
    ? 'linear-gradient(45deg, #1e3c72, #2a5298, #4a00e0)' 
    : 'transparent'};
  animation: ${props => props.$isScrolled ? gradientAnimation : 'none'} 15s ease infinite;
  box-shadow: ${props => props.$isScrolled ? '0 2px 20px rgba(0, 0, 0, 0.1)' : 'none'};
  padding: ${props => props.$isScrolled ? '12px 0' : '25px 0'};
`;

const NavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  max-width: 1200px;
  margin: 0 auto;
`;

const NavbarBrand = styled(Link)`
  font-size: 2.2rem;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  transition: all 0.3s ease;
  &:hover {
    color: #7efff5;
    transform: scale(1.05);
  }
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 35px;
  align-items: center;

  @media (max-width: 768px) {
    display: ${props => props.$isOpen ? 'flex' : 'none'};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: linear-gradient(45deg, #1e3c72, #2a5298, #4a00e0);
    padding: 30px;
    gap: 25px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
`;

const NavLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #7efff5;
    transition: all 0.3s ease;
  }

  &:hover {
    color: #7efff5;
    transform: translateY(-2px);
    &::after {
      width: 100%;
    }
  }
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #7efff5;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

const Header = ({ setCurrentLocale, currentLocale }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const intl = useIntl();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <HeaderWrapper $isScrolled={isScrolled}>
      <NavbarContent>
        <NavbarBrand to="/" aria-label={intl.formatMessage({ id: 'app.title' })}>
          {intl.formatMessage({ id: 'app.title' })}
        </NavbarBrand>
        <MenuButton 
          onClick={toggleMenu} 
          aria-expanded={isMenuOpen}
          aria-controls="main-menu"
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        >
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </MenuButton>
        <NavLinks $isOpen={isMenuOpen} id="main-menu" role="navigation" aria-label="Main menu">
          <NavLink to="/" aria-current={location.pathname === "/" ? "page" : undefined}>
            {intl.formatMessage({ id: 'nav.home' })}
          </NavLink>
          <NavLink to="/projects" aria-current={location.pathname === "/projects" ? "page" : undefined}>
            {intl.formatMessage({ id: 'nav.projects' })}
          </NavLink>
          <NavLink to="/services" aria-current={location.pathname === "/services" ? "page" : undefined}>
            {intl.formatMessage({ id: 'nav.services' })}
          </NavLink>
          <NavLink to="/about" aria-current={location.pathname === "/about" ? "page" : undefined}>
            {intl.formatMessage({ id: 'nav.about' })}
          </NavLink>
          <NavLink to="/contact" aria-current={location.pathname === "/contact" ? "page" : undefined}>
            {intl.formatMessage({ id: 'nav.contact' })}
          </NavLink>
          <LanguageSelector setCurrentLocale={setCurrentLocale} currentLocale={currentLocale} />
        </NavLinks>
      </NavbarContent>
    </HeaderWrapper>
  );
};

export default Header;