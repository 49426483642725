import React, { useState, useEffect } from 'react';
import DynamicBanner from './DynamicBanner';
import WhyKodikolab from './WhyKodikolab';
import ProjectCounter from './ProjectCounter';
import RecentProjects from './RecentProjects';
import NewsEvents from './NewsEvents';
import SEO from './seo'
import './HomePage.css';

const HomePage = () => {
  const [projectStats, setProjectStats] = useState(null);

  useEffect(() => {
    fetchProjectStats();
  }, []);

  const fetchProjectStats = async () => {
    try {
      const response = await fetch('/api/projects/statistics');
      const data = await response.json();
      setProjectStats(data);
    } catch (error) {
      console.error('Error fetching project statistics:', error);
    }
  };

  return (
    <>
      <SEO 
        title="Kodikolab - Innovative Software Solutions"
        description="Kodikolab provides cutting-edge software development services including web, mobile, and custom solutions."
        keywords="software development, web development, mobile apps, custom solutions"
        canonicalUrl="https://www.kodikolab.com"
      />
    <div className="home-page">
      <DynamicBanner />
      <WhyKodikolab />
      {projectStats && <ProjectCounter stats={projectStats} />}
      <RecentProjects />
      <NewsEvents />
    </div>
    </>
  );
};

export default HomePage;